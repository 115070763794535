import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useTranslation} from "../lang/TranslationContext";
import Header from "../blocks/Header";

const Watch = ({tg}) => {
    const { t } = useTranslation();

    return (
        <>
            <main>
                <section className="referral-section">
                    <div className="container">
                        <div className="main-top"><h1 className="main-top__title">{t('Live')}</h1></div>

                        <div className="referral-section__box">
                            <div className="main-top"><p>{t('Online broadcast of the mining process.')}</p></div>
                            
                        </div>
                    </div>
                </section>
            </main>

        </>
    );
};

export default Watch;
