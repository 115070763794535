import React, {useEffect, useState} from 'react';
import {useTranslation} from "../lang/TranslationContext";
import axios from "axios";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import { useNavigate } from "react-router-dom";

const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(1);
      alert('Success')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  

const Modals = ({tg, handleOverlayClick, activeModal, currentBalance, openModal, currentWallet, fetchHistory}) => {
    const  payWallet = "UQCY1y35RGjdbSIoHptQieSXvn85C4NRf4iJapvz9ThX-D-2"
    const {t} = useTranslation();
    const wallet = useTonWallet();
    const [tonConnectUi, connected ] = useTonConnectUI();
    const [amount, setAmount] = useState(10);
    const [out, setOut] = useState(1);
    const navigate = useNavigate();

    const updateAmount = (newAmount) => {

        if(newAmount < 0) {
            newAmount = 0.01
        }
        setAmount(newAmount)
    }

    const setWallet = async (walletAddress) => {
            try {
            const initData = tg && tg.initData ? tg.initData : null;
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/setWallet.php`, {
                            method: 'POST',
                            headers: {
                                    'Authorization': initData || '',
                                    'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({'wallet': walletAddress}),
                    });

                    const result = await response.json();
                    if (result.status === 'success') {
                   
                    } else {
                        alert(result.message)
                    }
            } catch (error) {
                console.log(error)
            }
    };

    useEffect(()=>{
        if (wallet) {
            if(currentWallet !== false) {
                const walletAddress = wallet.account.address; 
                if(walletAddress !== currentWallet) {
                    setWallet(walletAddress)
                }
            }
           
        }
    }, [wallet, currentWallet])

    const sendTransaction = async (amountInTon) => {
        if (!connected || !wallet) {
          console.error('Wallet is not connected');
          return;
        }
    
        try {
          const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 3600, 
            messages: [
              {
                address: payWallet,
                amount: (amountInTon * 1e9).toString(), 
                payload: '',
              },
            ],
          };
    
          const result = await tonConnectUi.sendTransaction(transaction);
          navigate("/");
        } catch (error) {
          console.log('Failed to send transaction:', error);
        }
      };


    const withdrawMoney = async () => {
        try {
          const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}/createWithdraw.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                          'currency_id': 3, 
                          'amount' : out
                        }),
                });

                const result = await response.json();
                if (result.status === 'success') {
                  alert(result.message)
                  fetchHistory()
                } else {
                     alert(result.message)
                }
        } catch (error) {
                console.log(error)
        }
};


    return (
        <>
            <div className={`overlay fixed-block ${activeModal ? 'active' : ''}`} data-overlay
                 onClick={handleOverlayClick}>
                <div className={`modal ${activeModal === 'wallet' ? 'active' : ''}`} data-popup="wallet"
                     style={activeModal === 'wallet' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>

                    <div className="modal__box">
                        <div className="main-top">
                            <h2 className="main-top__title">{t("Withdraw")}</h2>
                            <p>{t('This amount will be sent to your TON wallet.')}</p>
                        </div>
                        <form className="main-form main-form--mode">

                            <div className="main-form__inner">
                                <label htmlFor="coin_2" className="main-form__label">
                                    <span className="main-form__icon"><img width="20" height="20"
                                                                           src="./img/index/ton.png" alt="icon"/></span>
                                    <input style={{'minWidth': '150px'}} type="number" min="100" name="coin_2"
                                           id="coin_2" value={out}
                                           onChange={(event) => setOut(event.target.value)}
                                           className="main-form__input" placeholder="Enter amount"/>
                                </label>
                                <div className="main-form__wrapp">
                                    <span className="main-form__text">{t('Min amount')}: 1 TON</span>
                                </div>

                                <p>
                                    {t('At the moment you have available for withdrawal')} {currentBalance} {t('TON. Withdrawals are processed within 48 hours, but in some cases may take up to 5 business days. Thank you for your understanding and patience.')}
                                </p>
                            </div>

                            {wallet ? (<>
                                    <button onClick={() => {
                                        withdrawMoney()
                                    }} className="main-btn" data-btn-inner="succes">
                                    <span className="main-btn__bg"></span>
                                        {t("Withdraw")}
                                    </button>
                                </>
                            ) : (<>
                                <p>{t('Connect your TON wallet for payment')}</p>
                                <button type="button" onClick={() => tonConnectUi.openModal()} className="main-btn"
                                        data-btn-inner="succes"
                                        data-btn-inner="wallet">
                                    <span className="main-btn__bg"></span>
                                    {t('Connect Wallet')}
                                </button>
                            </>)}

                            <button className="main-btn main-btn--mode close">{t('Cancel')}</button>
                        </form>
                    </div>
                </div>

                <div className={`modal ${activeModal === 'connect' ? 'active' : ''}`} data-popup="connect"
                     style={activeModal === 'connect' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="modal__box">
                        <div className="modal__top">
                            <div className="modal__order">
                                <span className="modal__title">{t('Order details')}</span>
                                <ul className="modal__list">
                                    <li className="modal__item">
                                      <span
                                          className="modal__text">{t('Power')}: <i>{Number(amount * 10000).toFixed(2)} Gh/s</i>
                                      </span>
                                    </li>
                                    <li className="modal__item">
                                      <span className="modal__text">{t('Rental period')}: <i>30 {t('days')}</i>
                                      </span>
                                    </li>
                                    <li className="modal__item">
                                      <span className="modal__text">{t('Total income')}: <i>
                                          <img width="20" height="20" src="./img/index/ton.png"
                                               alt="icon"/> {Number(amount * 1.5).toFixed(2)} TON </i>
                                      </span>
                                    </li>
                                    <li className="modal__item">
                                      <span className="modal__text">{t('Daily income')}: <i>
                                          <img width="20" height="20" src="./img/index/ton.png"
                                               alt="icon"/> {Number(amount * 1.5 / 30).toFixed(2)} TON </i>
                                      </span>
                                    </li>
                                    <li className="modal__item">
                                      <span className="modal__text">{t('Price')}: <i>
                                          <img width="20" height="20" src="./img/index/ton.png"
                                               alt="icon"/> {amount} TON </i>
                                      </span>
                                    </li>
                                </ul>
                            </div>
                            {wallet ? (<>
                                    <button type="button" onClick={() => {
                                        sendTransaction(amount)
                                    }} className="main-btn main-btn--blue" data-btn-inner="wallet">
                                        <span className="main-btn__bg"></span>
                                        <span className="main-btn__icon">
                                                <svg width="21" height="21">
                                                    <use href="img/sprite/sprite.svg#icon"></use>
                                                </svg>
                                                </span>
                                        {t('Pay')}
                                    </button>

                                    <button className="main-btn main-btn--mode close">{t('Cancel')}</button>

                                    <p>{t('or')} {t('Send to the specified deposit address from your wallet')}.</p>
                                </>
                            ) : (<>

                                <p>{t('Connect your TON wallet to pay for your order')}</p>
                                <button onClick={() => tonConnectUi.openModal()}
                                        className="main-btn main-btn--blue" data-btn-inner="succes">
                                    <span className="main-btn__bg"></span>

                                    {t('Connect Wallet')}
                                </button>
                                <button className="main-btn main-btn--mode close">{t('Cancel')}</button>
                            </>)}

                            {wallet ? (<form className="main-form withdraw">
                                <div className="main-form__box">
                                    <div className="main-form__qr">
                                        <img width='182' height='184' src='img/qr.png' alt='image'/>
                                    </div>

                                    <span className="main-form__value"><b>{amount}</b> TON</span>

                                    <label className="main-form__label" htmlFor="address_1" data-parent-copy>
                                        <input onClick={() => {
                                            copyText(payWallet)
                                        }} readOnly type="text" name="address_1" id="address_1" className="main-form__input"
                                               value={payWallet}/>

                                        <button type="button" onClick={() => {
                                            copyText(payWallet)
                                        }} className="copy-btn" data-copy>
                                            <img width="18" height="18" src="img/sprite/copy.svg" alt="image"/>
                                        </button>
                                    </label>
                                </div>
                            </form>) : <></>}

                           

                        </div>
                    </div>


                </div>



                <div className={`modal ${activeModal === 'buy' ? 'active' : ''}`} data-popup="buy"
                     style={activeModal === 'buy' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="boost-section">


                        <div className="modal__box">
                            <div className="main-top">
                                <h1 className="main-top__title">
                                    {t("Buy boost")}
                                </h1>
                                <p>{t('Buy mining power for 10 days and earn more TON. The more power, the higher the income on the UniTON platform.')}</p>
                            </div>
                            <div className="main-form__inner">
                                <label htmlFor="coin" className="main-form__label">
                                    <input style={{'minWidth': '100px'}} type="number" min="0.01" max="10000"
                                           name="coin" id="coin"
                                           className="main-form__input"
                                           placeholder="" value={amount}
                                           onChange={(event) => updateAmount(event.target.value)}/>
                                    <span
                                        className="main-form__currency">TON</span> <span
                                    className="main-form__icon"><img
                                    width="20" height="20" src="./img/index/ton.png" alt="icon"/></span></label>

                                <div className="main-form__wrapp">
                                    <span className="main-form__text">{t('Min amount')}: 1 TON</span>
                                    <span className="main-form__text">{t('Max amount')}: 10000 TON</span>
                                </div>
                            </div>

                            <button onClick={() => {
                                openModal('connect')
                            }} className="main-btn main-btn--blue" data-btn-inner="order">
                                <span className="main-btn__bg"></span>
                                {t("Buy boost")}
                            </button>

                            <button className="main-btn main-btn--mode close">{t('Cancel')}</button>
                        </div>
                    </div>


                </div>


            </div>
        </>
    );
};

export default Modals;
