import {Route, Routes} from "react-router-dom";
import React from "react";
import Home from "../pages/Home";
import Friends from "../pages/Friends";
import Withdraw from "../pages/Withdraw";
import Tasks from "../pages/Tasks";
import Watch from "../pages/Watch";


const RouterList = ({tg, openModal, setCurrentBalance, setCurrentWallet, actualUser, history, fetchHistory}) => {

    return (
        <Routes>
            <Route exact path="/" element={<Home  tg={tg} openModal={openModal} setCurrentBalance={setCurrentBalance} setCurrentWallet={setCurrentWallet}/>} />
            <Route path="/friends" element={<Friends  tg={tg}/>} />
            <Route path="/withdraw" element={<Withdraw   tg={tg} user={actualUser} history={history} fetchHistory={fetchHistory}  openModal={openModal} setCurrentBalance={setCurrentBalance} setCurrentWallet={setCurrentWallet}/> } />
            <Route path="/tasks" element={<Tasks tg={tg}/>} />
            <Route path="/watch" element={<Watch tg={tg}/>} />
        </Routes>
    );
};

export default RouterList;

